import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import logo from "assets/location/ShambhaviLogo.jpeg";

import styles from "./navbar.module.css";

const Navbar = (props) => {
  const [isActive, setIsActive] = useState(false);

  const navigate = useNavigate();
  const redirectPage = (route) => {
    navigate(route);
    setIsActive(false);
  };
  const routes = [
    {
      name: "Home",
      route: "/",
    },
    {
      name: "Floor plan",
      route: "/floor-plan",
    },
    {
      name: "Location",
      route: "/location",
    },
    {
      name: "RERA Certificate",
      route: "/certificate",
    },
    {
      name: "Completed Projects",
      route: "/completed-projects",
    },
    {
      name: "Upcoming Projects",
      route: "/upcoming-projects",
    },
  ];
  return (
    <>
      <nav className={`flex ${styles.header}`}>
        <div className={`flex ${styles.cropContainer}`}>
          <div className={styles.crop}>
            <img src={logo} alt="Logo" className={styles.logo} />
          </div>
          <h1 className={styles.logoText}>Shambhavi Serendipity</h1>
        </div>

        <ul className={styles.navContainer}>
          {routes.map(({ name, route }) => (
            <li key={route} className={styles.route}>
              <NavLink
                className={({ isActive }) => (isActive ? styles.active : "")}
                to={route}
              >
                {name}
              </NavLink>
            </li>
          ))}
        </ul>
        <div
          className={`${styles.mobileNavContainer} ${
            isActive && styles.showMobileNav
          }`}
        >
          <ul>
            {routes.map(({ name, route }) => (
              <li
                key={route}
                className={styles.route}
                onClick={redirectPage.bind(null, route)}
              >
                {name}
              </li>
            ))}
          </ul>
        </div>
        <div
          className={`${styles.toggle} ${isActive && styles.active}`}
          onClick={() => setIsActive((current) => !current)}
        >
          <span></span>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
