import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

import ProjectLocation from "assets/location/location.jpg";
import certificate from "assets/location/certificate.jpg";
import upcomingProjects from "assets/location/upcoming project.jpg";
import completedProjects from "assets/location/Sharada-Serendipity.jpeg";

import Navbar from "./components/navbar";
import Loader from "components/Loader";

import "./App.css";

function App() {
  const Home = lazy(() => import("./pages/Home"));
  const FloorPlan = lazy(() => import("./pages/FloorPlan"));
  const Location = lazy(() => import("./pages/Location"));
  const ContactUs = lazy(() => import("pages/ContactUs"));

  return (
    <div className="wrapper">
      <Navbar />
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/floor-plan" element={<FloorPlan />} />
          <Route
            path="/location"
            element={
              <Location
                imageSource={ProjectLocation}
                redirect={
                  "https://www.google.com/maps/place/Shambhavi+Serendipity/@13.3653278,74.7137519,15z/data=!4m6!3m5!1s0x3bbcbd011d4ff633:0x5f87ba24a47730bf!8m2!3d13.3653278!4d74.7137519!16s%2Fg%2F11thqsqth5?entry=ttu"
                }
              />
            }
          />
          <Route
            path="/certificate"
            element={<Location imageSource={certificate} />}
          />
          <Route
            path="/upcoming-projects"
            element={<Location imageSource={upcomingProjects} />}
          />
          <Route
            path="/completed-projects"
            element={<Location imageSource={completedProjects} />}
          />
          <Route path="/contact-us" element={<ContactUs />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
